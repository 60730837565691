import {
  Grid, Typography, makeStyles, Box,
} from '@material-ui/core';
import React, { useState } from 'react';
import HeroBackground from './HeroBackground';
import StyledButton from '../atoms/StyledButton';
import FormCard from '../atoms/FormCard';

import TransformationFormDrawer from '../organisms/TransformationFormDrawer';
import StyledDrawer from '../atoms/StyledDrawer';
import HeaderInFashion from '../organisms/HeaderInFashion';
import { useDrawerContext, useStateDrawerContext } from '../../contexts/DrawerContext';
import HeroBackgroundTaNaModa from './HeroBackgroundTaNaModa';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',

    '& .title': {
      width: '80% !important',
    },
  },
  picture: {
    '@media screen and (max-width: 959px)': {
      display: 'none',
    },
  },
  button: {
    display: 'none',
    maxWidth: '85%',

    '@media screen and (max-width: 959px)': {
      display: 'block',
      maxWidth: '100%',
    },
  },
  hero: {
    minHeight: '50vh',
  },
}));

const SwitchAccountantFashionHero = () => {
  const classes = useStyles();
  const { setOpenDrawer } = useDrawerContext();
  const { openDrawer } = useStateDrawerContext();

  return (
    <>
      <HeaderInFashion openDrawer={() => setOpenDrawer(true)} />
      <HeroBackgroundTaNaModa page="trocar-contador">
        <Grid container spacing={2} className={classes.root}>
          <Grid item md={6} xs={12} className="text">
            <Typography className="span">
              <strong>Tá na moda</strong>
              {' '}
              empreender economizando
            </Typography>
            <Typography variant="h3" component="h1" className="title">
              Tenha uma contabilidade online com um
              {' '}
              <strong>desconto de R$ 400</strong>
            </Typography>

            {/* <Typography variant="body1" component="h2" className={classes.description}>
              Facilitamos as rotinas da sua empresa de comércio com uma plataforma
              online e oferecemos um desconto imperdível para você migrar
            </Typography> */}
            <Box className={classes.button}>
              <StyledButton
                text="QUERO APROVEITAR"
                variant="tnm"
                color="tnm"
                width="100%"
                onClick={() => setOpenDrawer(true)}
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={12} className={classes.picture}>
            <FormCard color="info" taNaModa className={classes.hero}>
              <TransformationFormDrawer typeForm="switchAccount" />
            </FormCard>
          </Grid>
        </Grid>

        {/* Mobile */}
        <StyledDrawer
          open={openDrawer}
          setOpen={setOpenDrawer}
          taNaModa
        >
          <TransformationFormDrawer noHeight typeForm="switchAccount" />
        </StyledDrawer>
      </HeroBackgroundTaNaModa>
    </>
  );
};

export default SwitchAccountantFashionHero;
