import React from 'react';
import MainFashion from '../../components/pages/MainFashion';
import SwitchAccountantFashion from '../../components/pages/business/SwitchAccountantFashion'

const TaNaModa = () => (
  <MainFashion page="trocar-de-contador">
    <SwitchAccountantFashion />
  </MainFashion>
)

export default TaNaModa;